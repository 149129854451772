import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { SxProps } from '@mui/material'
import { useTranslations } from '~/src/hooks/useTranslations'
import ButtonLink from '~/src/components/mui-wrappers/buttons/ButtonLink'
import routes from '~/src/router/enums/routes.enum'

interface Props {
  sx?: SxProps,
}

const GoToMenuButton = ({ sx }: Props) => {
  const { t } = useTranslations()

  return (
    <ButtonLink
      linkProps={{
        href: routes.MENU,
        resolve: true
      }}
      startIcon={<ArrowBackIcon />}
      variant='outlined'
      sx={sx}
    >
      {t('global.goToMenu')}
    </ButtonLink>
  )
}

export default GoToMenuButton
