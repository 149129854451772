import { Box } from '@mui/material'
import CartEmpty from '~/src/components/cart/CartEmpty'
import { Fragment } from 'react'
import CartItem from '~/src/components/cart/CartItem'
import Divider from '~/src/components/mui-wrappers/Divider'
import Title from '~/src/components/mui-wrappers/Title'
import Price from '~/src/components/Price'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CART_OF_FIRM_SELECTOR, CART_SUB_TOTAL_SELECTOR } from '~/src/stores/cart'
import { CartProduct } from '~/src/types/CartProduct'
import PriceHelper from '~/src/helpers/priceHelper'

const CartItems = () => {
  const cart = useEoValue(CART_OF_FIRM_SELECTOR)
  const total = useEoValue(CART_SUB_TOTAL_SELECTOR)
  const { t } = useTranslations()

  if (!cart.length) {
    return <CartEmpty/>
  }

  return (
    <>
      {cart.map((cartProduct: CartProduct, index: number) => (
        <Fragment key={index}>
          <CartItem cartProduct={cartProduct}/>
          <Divider/>
        </Fragment>
      ))}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Title variant='h5'>{t('cart.total')}</Title>
        <Title variant='h5'><Price price={PriceHelper.transform(total)}/></Title>
      </Box>
    </>
  )
}

export default CartItems
